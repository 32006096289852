import { Point } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { InlineSvgDirective } from '@app/concepts/inline-svg/directives/inline-svg.directive';
import { Dimensions } from '@app/exercises/interfaces/exercise.interfaces';
import { clamp, getAngleBetweenPoints, getDiagonal, getDistanceBetweenPoints, map, randomInRange } from '@utils/math';

interface BackgroundElementBasis {
  filename: string;
  x: number;
  y: number;
  // The width and height are optional to help ease the introduction of new elements
  // — they should be present on all elements once layouted
  width?: number;
  height?: number;
}

interface BackgroundElement extends BackgroundElementBasis {
  offsetX: number;
  offsetY: number;
  renderedX: number;
  renderedY: number;
  scale: number;
}
/**
 * A component that adds individually position background elements to the page, which react to pointer movements.
 */
@Component({
  selector: 'app-background-elements',
  imports: [CommonModule, InlineSvgDirective],
  templateUrl: './background-elements.component.html',
  styleUrls: ['./background-elements.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundElementsComponent implements OnChanges {
  @Input() isTeacher: boolean = false;
  @Input() enableMotion: boolean = false;

  /**
   * The size used for our reference design.
   * This is used as the basis for any viewport based calculations.
   */
  private baseViewportSize: Dimensions = {
    width: 1366,
    height: 768,
  };

  /**
   * The whitespace available for the base viewport size.
   */
  private baseWhitespaceWidth: number = this.calculateWhitespaceWidth(this.baseViewportSize.width);

  /**
   * Background elements to render for students.
   */
  public studentElements: BackgroundElementBasis[] = [
    // Left
    { filename: 'angle-ruler', x: 103, y: 49, width: 111, height: 57 },
    { filename: 'cube', x: -9, y: 144, width: 111, height: 57 },
    { filename: 'yellow-square', x: 138, y: 279, width: 10, height: 10 },
    { filename: 'paper-clip', x: 36, y: 332, width: 39, height: 45 },
    { filename: 'blue-dot', x: 14, y: 433, width: 9, height: 9 },
    { filename: 'cone', x: 143, y: 412, width: 78, height: 78 },
    { filename: 'compass', x: -30, y: 511, width: 65, height: 89 },
    { filename: 'blue-triangle-tilted', x: 103, y: 538, width: 11, height: 11 },
    { filename: 'triangular-ruler', x: -13, y: 607, width: 170, height: 105 },
    { filename: 'yellow-triangle', x: 135, y: 656, width: 105, height: 92 },

    // Right
    { filename: 'sphere', x: 1159, y: 6, width: 67, height: 67 },
    { filename: 'pen', x: 1239, y: 23, width: 125, height: 159 },
    { filename: 'yellow-circle', x: 1320, y: 130, width: 9, height: 9 },
    { filename: 'calculator', x: 1298, y: 187, width: 91, height: 97 },
    { filename: 'cylinder', x: 1181, y: 248, width: 76, height: 104 },
    { filename: 'blue-dot', x: 1304, y: 342, width: 9, height: 9 },
    { filename: 'blue-triangle', x: 1201, y: 418, width: 11, height: 10 },
    { filename: 'pyramid', x: 1302, y: 418, width: 61, height: 57 },
    { filename: 'ruler', x: 1142, y: 482, width: 167, height: 286 },
    { filename: 'eraser', x: 1281, y: 506, width: 43, height: 63 },
    { filename: 'aqua-square', x: 1164, y: 649, width: 10, height: 10 },
  ];

  /**
   * Background elements to render for teachers.
   * Each element is defined by its position and size in a viewport matching `baseViewportSize`.
   */
  public teacherElements: BackgroundElementBasis[] = [
    // Left
    { filename: 'Asset 12', x: -20, y: 74, width: 86, height: 92 },
    { filename: 'Asset 11', x: 180, y: 754, width: 68, height: 68 },
    { filename: 'Asset 6', x: 83, y: 421, width: 114, height: 138 },
    { filename: 'Asset 13', x: -37, y: 123, width: 54, height: 81 },
    { filename: 'Asset 14', x: -114, y: 509, width: 153, height: 95 },
    { filename: 'Asset 15', x: 17, y: 282, width: 87, height: 97 },
    { filename: 'Asset 16', x: -10, y: 679, width: 124, height: 95 },
    { filename: 'Asset 25', x: 99, y: 229, width: 16, height: 16 },
    { filename: 'Asset 29', x: 4, y: 474, width: 16, height: 16 },

    // Right
    { filename: 'Asset 8', x: 1270, y: 88, width: 122, height: 89 },
    { filename: 'Asset 10', x: 1296, y: 251, width: 69, height: 86 },
    { filename: 'Asset 17', x: 1098, y: 661, width: 130, height: 215 },
    { filename: 'Asset 18', x: 1191, y: 550, width: 118, height: 110 },
    { filename: 'Asset 19', x: 1309, y: 630, width: 161, height: 275 }, // Invisible ruler
    { filename: 'Asset 20', x: 1254, y: 57, width: 16, height: 16 },
    { filename: 'Asset 21', x: 1198, y: 269, width: 16, height: 16 },
    { filename: 'Asset 22', x: 1270, y: 419, width: 16, height: 16 },
    { filename: 'Asset 28', x: 1258, y: 717, width: 20, height: 18 },
  ];

  /**
   * The background elements rendered on screen.
   * Will be populated by either `studentElements` or `teacherElements` on changes.
   */
  public backgroundElements: BackgroundElement[] = [];

  /**
   * Hide the background elements from screen readers
   */
  @HostBinding('attr.aria-hidden') ariaHidden = true;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.updateBackgroundElementPositions();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: PointerEvent) {
    if (
      // Ignore event when motion is disabled
      !this.enableMotion ||
      // Ignore old browsers that don't support visual viewport
      !visualViewport ||
      // Ignore users that prefer reduced motion
      window.matchMedia('(prefers-reduced-motion)').matches
    ) {
      return;
    }

    // Calculate the pointer position in viewport units
    const pointerPosition: Point = {
      x: (event.clientX / visualViewport.width) * 100,
      y: (event.clientY / visualViewport.height) * 100,
    };

    // Add a slight offset to each element based on the pointer position
    this.backgroundElements = this.backgroundElements.map((element) => {
      const distance = getDistanceBetweenPoints(element, pointerPosition);
      const angle = getAngleBetweenPoints(element, pointerPosition);

      const multiplier = -0.2;
      const effect = Math.cbrt(distance) * multiplier;

      // Apply an offset based on the pointer position
      const offsetX = effect * Math.cos(angle);
      const offsetY = effect * Math.sin(angle);

      return {
        ...element,
        offsetX,
        offsetY,
      };
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isTeacher) {
      // Update the background elements based on the user type
      if (this.isTeacher) {
        this.backgroundElements = this.prepareBackgroundElements(this.teacherElements, 'teacher/');
      } else {
        this.backgroundElements = this.prepareBackgroundElements(this.studentElements, 'student/');
      }

      // Update the positions to match the current viewport
      this.updateBackgroundElementPositions();
    }
  }

  public trackByFilename(index: number, element: BackgroundElement): string {
    return element.filename;
  }

  /**
   * Adds the missing width and height attributes to the SVGs when loaded.
   * The attribute values are based on the viewBox attribute.
   * Adds a random delay to the fade-in-animation.
   */
  public onSvgLoaded(svg: SVGSVGElement | null): void {
    if (!svg) {
      // The SVG element *should* never be null, but it will be if the SVG file isn't valid
      return;
    }

    const viewBox = svg.getAttribute('viewBox');

    if (viewBox) {
      const [x, y, width, height] = viewBox.split(' ').map(parseFloat);
      svg.setAttribute('width', (width - x).toString());
      svg.setAttribute('height', (height - y).toString());
      svg.style.animationDelay = randomInRange(0, 1200) + 'ms';
    }
  }

  /**
   * Converts the given basis background elements to "real" background elements by:
   * - Converting the coordinates from px values to vw values
   * - Adding a filename prefix
   * - Setting a default offset position
   * - Setting a default rendered position
   * - Setting a default scale
   */
  private prepareBackgroundElements(
    backgroundElements: BackgroundElementBasis[],
    filenamePrefix: string = '',
  ): BackgroundElement[] {
    return backgroundElements.map((backgroundElement) => ({
      ...backgroundElement,
      // Convert the x/y coordinates to viewport units from the center of the element
      x: ((backgroundElement.x + (backgroundElement.width || 100) / 2) / this.baseViewportSize.width) * 100,
      y: ((backgroundElement.y + (backgroundElement.height || 100) / 2) / this.baseViewportSize.height) * 100,
      filename: filenamePrefix + backgroundElement.filename,
      offsetX: 0,
      offsetY: 0,
      renderedX: 0,
      renderedY: 0,
      scale: 1,
    }));
  }

  /**
   * Updates the position of the elements based on the viewport size.
   */
  private updateBackgroundElementPositions(): void {
    const viewportSize: Dimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const whitespaceWidth = this.calculateWhitespaceWidth(viewportSize.width);

    const whitespaceWidthVw = (whitespaceWidth / viewportSize.width) * 100;
    const baseWhitespaceWidthVw = (this.baseWhitespaceWidth / this.baseViewportSize.width) * 100;

    const viewportDiagonal = getDiagonal(viewportSize.width, viewportSize.height);
    const baseDiagonal = getDiagonal(this.baseViewportSize.width, this.baseViewportSize.height);

    const scale = clamp((viewportDiagonal / baseDiagonal) * 0.8, 0.5, 1.5);

    this.backgroundElements = this.backgroundElements.map((backgroundElement) => {
      const constrainedX =
        backgroundElement.x < 50
          ? map(backgroundElement.x, 0, baseWhitespaceWidthVw, 0, whitespaceWidthVw)
          : map(backgroundElement.x, 100 - baseWhitespaceWidthVw, 100, 100 - whitespaceWidthVw, 100);

      const constrainedY = backgroundElement.y;

      return { ...backgroundElement, renderedX: constrainedX, renderedY: constrainedY, scale };
    });
  }

  /**
   * Calculates the whitespace between the content and the edge of the viewport
   */
  private calculateWhitespaceWidth(viewportWidth: number): number {
    return viewportWidth > 375 ? viewportWidth * 0.1215 : 16;
  }
}
